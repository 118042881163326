@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Signika:wght@300..700&display=swap';

@import 'https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200&family=Hind+Siliguri:wght@300;400;500;600;700&family=Noto+Sans+Buhid&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Signika:wght@500&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&family=Vast+Shadow&display=swap';

@import 'https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200&family=Hind+Siliguri:wght@300;400;500;600;700&family=Noto+Sans+Buhid&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Signika:wght@500&family=Vast+Shadow&display=swap';
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

      @layer utilities{
        .nav{
          @apply relative ml-10 text-gray-500 font-Font tracking-[1px] hover:text-gray-500
        }
        .nav::after{
          @apply content-[''] bg-black h-[3px] w-[0%] left-0 -bottom-[5px] absolute duration-500 
        }
        .nav:hover::after{
          @apply w-[100%]
        }
      }
 